import * as React from "react"
import {Link} from "gatsby"
import $ from 'jquery'

import Layout from "../components/layout"
import Seo from "../components/seo"
import {Component} from "react";

export class RefundPolicy extends Component {
    componentDidMount() {
        $(document).ready(function () {
            $('a[href*=\\#]').bind('click', function (e) {
                var target = $(this).attr("href");
                $('html, body').stop().animate({
                    scrollTop: $(target).offset().top + 10
                }, 600, function () {
                    window.location.hash = target;
                });
                return false;
            });
        });

        $(window).scroll(function () {
            var scrollDistance = $(window).scrollTop();

            $('.content-section').each(function (i) {
                if ($(this).position().top - 10 <= scrollDistance) {
                    $('.nav-helper a.active-side').removeClass('active-side');
                    $('.nav-helper a').eq(i).addClass('active-side');
                }
            });
        }).scroll();


    }

    render() {
        return (
            <Layout>
                <div className="content">
                    <div className="content-body">
                        <div className="content-section" id="refund-policy">
                            <h1>Refund policy</h1>
                            <br/>
                            <p>We're so convinced you'll absolutely love our services, that we're willing to offer a 14
                                day risk-free money back guarantee. If you are not satisfied with the service for any
                                reason
                                you can get a
                                refund within 14 days of making a purchase. Please keep in mind that even though we
                                offer a
                                full money back
                                guarantee, we will issue a refund only for the unused portion of the service.</p>
                        </div>

                        <div className="content-section" id="contact-us">
                            <h2>Contacting us</h2>
                            <p>If you would like to contact us concerning any matter relating to this Refund Policy, you
                                may send an email
                                to &#99;&#111;n&#110;&#101;c&#116;&#64;thehype&#114;s&#116;&#97;&#99;k.&#99;om</p>
                            <p>This document was last updated on March 26, 2020</p>
                        </div>
                    </div>
                    <div className="content-nav">
                        <div className="nav-helper">
                            <a href="#refund-policy" className="nav-helper__link active-header">Refund Policy</a>
                            <a href="#contact-us" className="nav-helper__link">Contacting us</a>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

export const Head = () => <Seo title="Refund Policy of Hyperstack"
                               description="We offer 14 day risk-free money back guarantee"/>

export default RefundPolicy
